export const COMMON_EN = {
	'My Products': 'My Products',
	'Transfered Batches': 'Transfered Batches',
	'No products added yet': 'No products added yet',
	Knowledgebase: 'Knowledgebase',
	Weather: 'Weather',
	Settings: 'Settings',
	form: {
		Submit: 'Submit',
		Save: 'Save',
		Cancel: 'Cancel'
	},
	productCategory: {
		Flora: 'Crop',
		Fauna: 'Livestock',
		Plant: 'Plant',
		Cereal: 'Cereals',
		Legume: 'Legumes',
		Vegetables: 'Vegetables',
		Vegetable: 'Vegetable',
		Spice: 'Spice',
		Forage: 'Forage',
		'Medicinal And Aromatic': 'Medicinal And Aromatic',

		Fruit: 'Fruit',
		'Oil Seed Crop': 'Oil Seed Crop',
		Beverage: 'Beverage',
		'Small Ruminants': 'Small Ruminants',
		'Large Ruminants': 'Large Ruminants',
		'Large Ruminant': 'Large Ruminant',
		'Small Ruminant': 'Small Ruminant',

		'Feed and forage': 'Feed and forage',
		Fibre: 'Fibre',
		Ornamental: 'Ornamental',
		Other: 'Others',
		Animal: 'Animal',
		Pig: 'Pig',
		Poultry: 'Poultry',
		Equine: 'Equine',
		Camel: 'Camel',
		Fish: 'Fishes',
		Insect: 'Insects',
		'No info': 'No info',
		'No products': 'No products'
	}
};
